<template>
  <div class="cover" :style="{ backgroundImage: `url(${tarotBoxHistory.output_page_meta_data.splash_image_url})` }">
      <img id="cover-title" :src="tarotBoxHistory.output_page_meta_data.title_image_url">
      <img id="cover-cta" :src="tarotBoxHistory.output_page_meta_data.cta_image_url">
    <div v-safe-area-top>
      <div v-if="!isShare" class="out-area" @click="goBack"/>
      <div v-if="!isShare" class="out-button" @click="goBack">
        <img :src="require('@/tarot_box/pages/box_result_v2/assets/xbutton-lg.svg')">
      </div>
    </div>
  </div>
</template>

<script>
import {
  changeBottomSafeAreaColor,
  changeTopSafeAreaColor,
  logEvent,
  showBottomBar
} from "@/tarot_box/helper/native_api";

export default {
  name: "CoverImage",
  props: {
    tarotBoxHistory: {
      type: Object,
    },
    isShare: {
      type: Boolean,
    },
  },
  methods: {
    goBack(){
      this.$router.go(-1);
      showBottomBar();
      changeBottomSafeAreaColor('#FF1E2352');
      changeTopSafeAreaColor('#FFFFFFFF');
      logEvent("result_cover_out_click", {});
    }
  }
}
</script>

<style scoped>
.cover{
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  margin: 0;
  padding: 0;

  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  outline: none;
  box-shadow: none;
}

#cover-title{
  position: absolute;
  left: 50%;
  top: 23%;
  transform: translateX(-50%);
  width: 78%;
  outline: none;
  box-shadow: none;
}

#cover-cta{
  position: absolute;
  bottom: 9.2%;
  left: 50%;
  transform: translateX(-50%);
  height: 9.2%;
  outline: none;
  box-shadow: none;
}

.out-area{
  position: absolute;
  top: 14px;
  left: 14px;
  width: 60px;
  height: 60px;
  background-color: transparent;
  z-index: 8;
  outline: none;
  box-shadow: none;
}

.out-button{
  position: absolute;
  top: 45px;
  left: 24px;
  width: 40px;
  height: 40px;
  background-color: rgba(0,0,0,0.7);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
}


</style>