import HomePage from "@/tarot_box/pages/home/HomePage.vue"
import InputPage from "@/tarot_box/pages/box_input_v2/InputPage.vue";
import OutputPage from "@/tarot_box/pages/box_result_v2/OutputPage.vue";

export const TarotBoxRoutes = [
    { path: "/", redirect: "/tarot_box/home?is_web=true"},
    { path: "/tarot_box/home", component: HomePage },
    { path: "/tarot_box/mytarot", component: () => import('./pages/my_tarot/MyTarotPage.vue')  },
    { path: "/tarot_box/input/:boxId/:language?", component: () => import('./pages/box_input/InputPageTemplate.vue') },
    { path: "/tarot_box/input_v2/:boxId/:language?", component: InputPage},
    { path: "/tarot_box/result/:boxId/:language?", component: () => import('./pages/box_result/OutputPageTemplate2.vue') },
    { path: "/tarot_box/today_tarot", component: () => import('./pages/today_fortune/TodayFortune.vue') },

    //for the test
    { path: "/tarot_box/result/:boxId/:language?", component: () => import('./pages/box_result/OutputPageTemplate2.vue') },
    { path: "/tarot_box/result_v2/:boxId/:language?", component: OutputPage},

    { path: "/tarot_box/review/:boxId", component: () => import('./pages/box_result/WriteReviewPage.vue'), meta: { fromPath: '' } },
    { path: "/tarot_box/review_complete", component: () => import('./pages/box_result/WriteReviewCompletePage.vue'), meta: { fromPath: '' }  },
    { path: "/new_result/:boxId", redirect: to => { return `/tarot_box/result/${to.params.boxId}`;} },
    { path: "/tarot_box/checkout/:boxId", component: () => import('./pages/web_checkout/pages/WebCheckoutPage.vue')},
    { path: "/tarot_box/purchase_complete", component: () => import('./pages/web_checkout/pages/WebCheckoutCompletePage.vue')},

    //TEMP
    { path: "/tarot_box/event", component: () => import('./pages/event_page/EventPage.vue')},
    { path: "/tarot_box/event2", component: () => import('./pages/event_page/EventPage2.vue')},
    { path: "/tarot_box/labs/240703", component: () => import('./pages/labs/pages/BridgePage.vue')},

    { path: "/us", component: () => import('../../src/test/AmericaPage.vue')},
    { path: "/mason", component: () => import('../../src/mason/MasonMainPage.vue')},
    { path: "/signin", component: () => import('../../src/sign_in/SignInPage.vue')},
    { path: "/mypage_v1", component: () => import('../../src/my_page/MyPageV2.vue')},
    { path: "/paywall", component: () => import('../../src/paywall/CloverPaywall.vue')},
    { path: "/subpaywall", component: () => import('../../src/paywall/MateChatSubscriptionPaywall.vue')},

    // { path: "/tarot_box/mytarot", component: MyTarotPage },
    // { path: "/tarot_box/input/:boxId/:language?", component: InputPageTemplate },
    // { path: "/tarot_box/input_v2/:boxId/:language?", component: InputPage},
    // { path: "/tarot_box/result/:boxId/:language?", component: OutputPageTemplate },
    // { path: "/tarot_box/today_tarot", component: TodayFortune },
    //for the test
    // { path: "/tarot_box/result/:boxId/:language?", component: OutputPageTemplate2},
    // { path: "/tarot_box/result_v2/:boxId/:language?", component: OutputPage},
    //
    //
    // { path: "/tarot_box/review/:boxId", component: WriteReviewPage, meta: { fromPath: '' } },
    // { path: "/tarot_box/review_complete", component: WriteReviewCompletePage, meta: { fromPath: '' }  },
    // { path: "/new_result/:boxId", redirect: to => {
    //         return `/tarot_box/result/${to.params.boxId}`;}
    // },
    // { path: "/tarot_box/checkout/:boxId", component: WebCheckoutPage},
    // { path: "/tarot_box/purchase_complete", component: WebCheckoutCompletePage},
    //
    // //TEMP
    // { path: "/tarot_box/event", component: EventPage},
    // { path: "/tarot_box/event2", component: EventPage2},
    // { path: "/tarot_box/labs/240703", component: BridgePage},
    // { path: "/us", component: AmericaPage},
    //
    // { path: "/mason", component: MasonMainPage},
    // { path: "/signin", component: SignInPage},
    // { path: "/mypage_v1", component: MyPage},
    // { path: "/paywall", component: CloverPaywall},
    // { path: "/subpaywall", component: MateChatSubscriptionPaywall},
];