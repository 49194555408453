<template>
  <div class="confirm-popup-back-page">
    <div class="confirm-popup">
      <ShimmerImage
          :src="thumbnailImageUrl"
          :imageStyle="{ width: '126px' }"
          :heightRatio="176/126"
          :isCircle="false"
          :borderRadius="'8px'"/>

      <div class="confirm-popup-text-wrapper">
        <div class="title title2">
          즐거운 시간 되셨나요?
        </div>
        <div class="subtitle text2">
          소중한 의견을 남겨주시면<br>
          클로버를 돌려드려요🍀
        </div>
        <div class="clover-value title3">
          +{{feedbackReward}}
          <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.46093 9.00001C9.47406 8.98559 9.4871 8.9711 9.50007 8.95653C9.51304 8.9711 9.52609 8.98559 9.53921 9C9.52609 9.01442 9.51304 9.02891 9.50007 9.04348C9.4871 9.02891 9.47406 9.01442 9.46093 9.00001ZM9.50007 16.2693C8.55284 17.3332 7.19288 18 5.68188 18C2.81987 18 0.499756 15.6076 0.499756 12.6564C0.499756 11.242 1.0327 9.95587 1.90283 9.00001C1.0327 8.04415 0.499756 6.75806 0.499756 5.34361C0.499756 2.39242 2.81987 0 5.68188 0C7.19288 0 8.55284 0.666842 9.50007 1.73069C10.4473 0.666842 11.8073 0 13.3183 0C16.1803 0 18.5004 2.39242 18.5004 5.34361C18.5004 6.75806 17.9674 8.04415 17.0973 9.00001C17.9674 9.95587 18.5004 11.242 18.5004 12.6564C18.5004 15.6076 16.1803 18 13.3183 18C11.8073 18 10.4473 17.3332 9.50007 16.2693Z" fill="#4BD1A9"/>
            <path d="M9.91798 13.1878C9.35635 13.1878 8.83072 13.0906 8.34109 12.8962C7.85146 12.6945 7.42304 12.4173 7.05582 12.0645C6.69579 11.7117 6.41138 11.3013 6.20256 10.8332C6.00095 10.358 5.90015 9.84678 5.90015 9.29954C5.90015 8.74511 6.00095 8.23388 6.20256 7.76585C6.41138 7.29062 6.69939 6.8766 7.06662 6.52378C7.43384 6.17096 7.85866 5.89734 8.34109 5.70293C8.83072 5.50132 9.35635 5.40051 9.91798 5.40051C10.3356 5.40051 10.7388 5.45812 11.1277 5.57332C11.5165 5.68853 11.8765 5.85054 12.2077 6.05935C12.5461 6.26816 12.8342 6.52018 13.0718 6.8154L12.0133 7.86306C11.7397 7.52464 11.4229 7.26902 11.0629 7.09621C10.71 6.9234 10.3284 6.837 9.91798 6.837C9.57957 6.837 9.26275 6.9018 8.96753 7.03141C8.67231 7.15382 8.4167 7.32663 8.20068 7.54984C7.98467 7.76585 7.81546 8.02507 7.69305 8.32749C7.57065 8.6227 7.50944 8.94672 7.50944 9.29954C7.50944 9.64516 7.57065 9.96918 7.69305 10.2716C7.81546 10.5668 7.98827 10.826 8.21148 11.0492C8.4347 11.2653 8.69391 11.4345 8.98913 11.5569C9.29155 11.6793 9.61917 11.7405 9.97199 11.7405C10.368 11.7405 10.7388 11.6577 11.0845 11.4921C11.4301 11.3265 11.7361 11.0889 12.0025 10.7792L13.0286 11.7945C12.791 12.0825 12.5065 12.3309 12.1753 12.5397C11.8441 12.7485 11.4841 12.9106 11.0953 13.0258C10.7136 13.1338 10.3212 13.1878 9.91798 13.1878Z" fill="#121212"/>
          </svg>
        </div>
      </div>

      <div class="confirm-popup-button-area">
        <div class="confirm-popup-button" v-wave @click="skipResultView()">
          나중에 할게요
        </div>
        <div class="confirm-popup-button" v-wave @click="handleButtonFunction()">
          리뷰 쓰기
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ShimmerImage from "@/tarot_box/components/shimmer/ShimmerImage.vue";
import {
  changeBottomSafeAreaColor,
  changeTopSafeAreaColor,
  logEvent,
  showBottomBar
} from "@/tarot_box/helper/native_api";
import {getTarotBoxItemsByFetch} from "@/tarot_box/helper/mytarot_api";

export default {
  name: 'ReviewPromptPopup',
  components: {ShimmerImage},
  mounted() {
    this.addSafeAreaColorTop('overlay');
    this.addSafeAreaColorBottom('overlay');

    if (Array.isArray(this.tarotBoxes) && this.tarotBoxes.length > 0) {
      const box = this.tarotBoxes.find(box => box.id === parseInt(this.$route.params.boxId));
      if (box?.title) {
        this.thumbnailImageUrl = box.thumbnailImgUrl;
        logEvent('mytarot_review_popup', {
          'tarot_box_id': this.$route.params.boxId,
        });
      } else {
        this.setDefaultThumbnailAndRefresh();
      }
    } else {
      this.setDefaultThumbnailAndRefresh();
    }
  },
  beforeUnmount() {
    if(!this.fromV2){
      this.previousSafeAreaColorTop();
      this.previousSafeAreaColorBottom();
    }
  },
  computed: {
    ...mapGetters(['user', 'tarotBoxes']),
  },
  data() {
    return {
      userInfo: {},
      thumbnailImageUrl: '',
    };
  },
  emits: ['exit'],
  props: {
    historyId: {
      type: Number,
    },
    hashId: {
      type: String,
    },
    feedbackReward: {
      type: Number,
    },
    fromV2:{
      type: Boolean,
    }
  },
  methods: {
    ...mapActions(['addSafeAreaColorTop', 'addSafeAreaColorBottom', 'previousSafeAreaColorTop', 'previousSafeAreaColorBottom', 'setTarotBoxesData']),
    handleButtonFunction() {
      logEvent('mytarot_review_popup_go', {
        'tarot_box_id': this.$route.params.boxId,
      });
      const url = new URL(window.location.href);
      const insetBottom = url.searchParams.get("inset_bottom");
      const insetTop = url.searchParams.get("inset_top");
      const buildNumber = url.searchParams.get("build_number");
      const osType = url.searchParams.get("os_type");
      const tarotUserId = url.searchParams.get("tarot_user_id");
      const phoneNumber = url.searchParams.get("phone_number");
      this.$router.push(`/tarot_box/review/${this.$route.params.boxId}?history_id=${this.historyId}&hash_id=${this.hashId}&build_number=${buildNumber}&inset_top=${insetTop}&inset_bottom=${insetBottom}&os_type=${osType}&tarot_user_id=${tarotUserId}&phone_number=${phoneNumber}`);
    },
    skipResultView() {
      showBottomBar();
      changeTopSafeAreaColor('#FFFFFFFF');
      changeBottomSafeAreaColor('#FF171B2B');
      this.$emit('exit')
    },
    setDefaultThumbnailAndRefresh() {
      this.refreshTarotBoxes();
      this.thumbnailImageUrl = 'https://firebasestorage.googleapis.com/v0/b/meta-play-project.appspot.com/o/thumbnail_default.jpg?alt=media&token=cbd4c705-25c2-467c-a105-c48e0687e3a2';
    },
    async refreshTarotBoxes() {
      let res = await getTarotBoxItemsByFetch(this.user.phoneNumber, this.user.userId, false);
      if (res.result === 'success') {
        this.setTarotBoxesData(res.data.tarot_boxes);
      } else {
        let res2 = await getTarotBoxItemsByFetch('', '', true);
        if (res2.result === 'success') {
          this.setTarotBoxesData(res2.data.tarot_boxes);
        }
      }
    }
  },
}
</script>

<style scoped>
.confirm-popup-back-page{
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  background: rgba(0, 0, 0, 0.80);
}

.confirm-popup{
  position: absolute;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 32px;
  width: 90%;
  height: fit-content;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 46px 15px 16px 15px;
  box-sizing: border-box;

  border-radius: 16px;
  border: 1px solid var(--Grayscale-Gray9);
  background: var(--Grayscale-Gray12);
}

.confirm-popup-text-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  .title {
    color: var(--Color-palette-Primary-Default);
  }

  .subtitle {
    color: var(--Grayscale-Gray4);
  }

  .clover-value {
    padding: 4px 10px;
    border-radius: 90px;
    background: var(--Grayscale-Gray11);

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
    color: var(--Color-palette-Primary-Default);
  }
}

.confirm-popup-button-area {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  gap: 9px;
}

.confirm-popup-button {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0;

  background: #1E2352;
  color: #FFF;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 700;
}

.confirm-popup-button:first-child {
  background: #E9E9E9;
  color: #6E6E6E;
}

.confirm-popup-button:last-child {
  background: #1E2352;
  color: #FFF;
}
</style>