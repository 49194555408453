export default {
    install(Vue) {
        Vue.mixin({
            created() {
                this.$constants = {
                    tarotCardCount: 156,
                    tarotCardGrid: {
                        width: 322,
                        height: 387
                    },
                    tarotCardAspectRatio: 108/64
                };
            }
        });
    }
};