<template>
  <div class="modal" v-if="isActive">
    <div class="overlay" @click="closePopup"></div>
    <div class="delete-popup-container" ref="popupContainer">
      <div class="popup-header">
        <span class="title2" style="margin-bottom: 12px;">
          {{ isSuccess ? '클로버 충전 완료' : '충전하지 못했어요' }}
        </span>
        <span v-if="!isSuccess" class="text2" style="margin-bottom: 20px;">
          결제 수단을 확인해 보고 다시 시도해 주세요
        </span>
        <div v-if="isSuccess" class="clover-status">
          <div class="text2">충전된 클로버</div>
          <div class="clover">
            <div class="title3">{{clover}}</div>
            <img src="@/tarot_box/pages/box_input_v2/assets/clover.svg">
          </div>
        </div>
      </div>
      <div class="popup-content">

        <ShimmerImage
            :src="popupIconUrl"
            :imageStyle="{ width: '40vw' }"
            :heightRatio="popupRatio"
            :isCircle="false"/>
      </div>
      <div class="popup-footer">
        <div
            class="btn2"
            @click="$emit('close')"
            :style="buttonStyle">
          {{ isSuccess ? '확인' : '확인' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ShimmerImage from "@/tarot_box/components/shimmer/ShimmerImage.vue";

export default {
  name: "RestorePopup",
  components: {ShimmerImage},
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    isSuccess: {
      type: Boolean,
      default: true,
    },
    clover: {
      type: Number,
      default: 0
    }
  },
  emits: ['close'],
  computed: {
    buttonStyle() {
      return {
        flex: 1,
        background: this.isSuccess ? 'var(--Color-palette-Primary-Default)' : 'var(--Color-palette-Primary-Default)',
        color: this.isSuccess ? 'var(--Grayscale-Gray12)' : 'var(--Grayscale-Gray12)'
      }
    },
    popupIconUrl() {
      return this.isSuccess ? require('@/paywall/assets/clover-success.jpg') : require('@/paywall/assets/clover-failed.jpg');
    },
    popupRatio() {
      return this.isSuccess ? 283/334 : 1;
    }
  },
}
</script>

<style scoped>
.title2, .title3, .text2 {
  font-family: 'SUIT Variable',sans-serif;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}
.overlay {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 99999;
}

.delete-popup-container {
  position: fixed;
  top: 50%; /* 화면의 수직 중앙에 위치하도록 설정 */
  left: 50%; /* 화면의 수평 중앙에 위치하도록 설정 */
  transform: translate(-50%, -50%); /* 팝업의 중심을 화면 중심에 정확히 맞추기 위해 */
  width: calc( 100vw - 40px);
  padding: 52px 0 30px 0;
  background-color: #FFFFFF;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  z-index: 100000;
  border-radius: 8px;
}

.popup-header{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.popup-footer{
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin: 50px 16px 0 16px;
  justify-content: space-between;
  align-items: center;
}

.popup-content{
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.popup-content img{
  width: 50%;
  height: auto;
}

.clover-status{
  width: 200px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 5px auto;
  margin-bottom: 25px;
  padding: 8px 14px;
  border-radius: 90px;
  border: 0.5px #DBDBDB;
  background: #F0F0F0;
}

.clover{
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
  justify-content: center;
}

.btn2{
  flex: 1;
  display: flex;
  padding: 12px 0;
  align-items: center;
  justify-content: center;
  background-color: var(--Grayscale-Gray10);
  color: var(--Grayscale-Gray6);
  font-size: 16px;
  font-weight: 700;
  border-radius: 6px;
}


</style>