<template>
  <ShimmerImage :src="src"
                :imageStyle="{ width: imageWidth, border: '0.5px solid var(--Grayscale-Gray10)'}"
                :borderRadius="borderRadius"
                :heightRatio="223/160"/>
</template>

<script>
import ShimmerImage from "@/tarot_box/components/shimmer/ShimmerImage.vue";

export default {
  name: "ThumbnailImageDynamic",
  components: {ShimmerImage},
  props: {
    src: {
      type: String,
      required: true,
    },
    imageWidth: {
      type: String,
      required: true,
    },
    borderRadius: {
      type: String,
      default: '8px',
    },
  },
}
</script>

<style scoped>

</style>