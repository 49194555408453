<template>
  <div ref="trigger">
    <slot></slot>
  </div>
</template>

<script>
export default {
  data() {
    return {
      observer: null,
      option: {
        root: null,
        threshold: 0.3,
      },
    };
  },
  methods: {
    handleIntersect: function (target) {
      if (target.isIntersecting) {
        this.$refs.trigger.style.opacity = "1";
        this.$emit(`triggerFadeIn`);
      }
    },
  },
  mounted() {
    this.observer = new IntersectionObserver((entries) => {
      this.handleIntersect(entries[0]);
    }, this.option);
    this.observer.observe(this.$refs.trigger);
  },
};
</script>

<style scoped>
div {
  opacity: 0;
}
</style>