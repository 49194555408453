<template>
  <header v-safe-area-top>
    <div class="button-area">
      <button class="back-button" @click="this.$emit('goBack')">
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M9.74211 0.257942C9.39825 -0.0861436 8.83848 -0.0861436 8.49462 0.257942L0.657892 7.37173C0.314035 7.71582 0.314035 8.28396 0.657892 8.62805L8.49462 15.7418C8.83848 16.0859 9.39825 16.0859 9.74211 15.7418C10.086 15.3977 10.086 14.8296 9.74211 14.4855L2.52911 8.00389L9.74211 1.52227C10.086 1.16218 10.086 0.602029 9.74211 0.257942Z" fill="black"/>
        </svg>
      </button>

      <span class="title">
        카드선택
      </span>

      <button class="reset-button" @click="this.$emit('resetCards')">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
          <path d="M8.00016 17.3334C8.00016 15.1334 8.8935 13.1334 10.3468 11.68L8.4535 9.78671C6.5335 11.72 5.3335 14.3867 5.3335 17.3334C5.3335 22.7734 9.40016 27.2534 14.6668 27.9067V25.2134C10.8935 24.5734 8.00016 21.2934 8.00016 17.3334ZM26.6668 17.3334C26.6668 11.44 21.8935 6.66671 16.0002 6.66671C15.9202 6.66671 15.8402 6.68004 15.7602 6.68004L17.2135 5.22671L15.3335 3.33337L10.6668 8.00004L15.3335 12.6667L17.2135 10.7867L15.7735 9.34671C15.8535 9.34671 15.9335 9.33337 16.0002 9.33337C20.4135 9.33337 24.0002 12.92 24.0002 17.3334C24.0002 21.2934 21.1068 24.5734 17.3335 25.2134V27.9067C22.6002 27.2534 26.6668 22.7734 26.6668 17.3334Z" fill="white"/>
        </svg>
      </button>
    </div>

    <span class="description">
      정확한 해석을 위해 마음을 담아 골라주세요
    </span>
  </header>
</template>

<script>
export default {
  emits: ['goBack', 'resetCards'],
}
</script>

<style scoped>
header {
  position: fixed;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 0 19px;
  box-sizing: border-box;
  z-index: 20
}

.title {
  color: #FFF;
  font-size: 22px;
  font-weight: 700;
}

.description {
  color: #E9C5FA;
  font-size: 14px;
  font-weight: 500;
}

.button-area {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

button {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  box-shadow: 5px 5px 25px 0 rgba(0, 0, 0, 0.05);
  box-sizing: border-box;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.back-button {
  border: 1.2px solid #000;
  background: #FFF;
}

.reset-button {
  background: #7579A5;
}
</style>