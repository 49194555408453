<template>
  <div class="page">
    <div class="popup">
      <div class="contents">
        <div class="text">
          <div class="title">[장애 복구 완료 공지]</div>
          <div class="detail">
            금일 서비스 이용에 불편을 드린 점<br>
            마이타로 팀 전체가 송구스러운 마음입니다.<br><br>

            작업 진행 사항은 다음과 같습니다.<br><br>

            8월 16일, 새벽 3시 이전에 결제하신<br>
            유료 콘텐츠들의 복구가 완료 되었습니다.<br><br>

            8월 16일 새벽 3시 - 오후 4시에 결제하신<br>
            유료 콘텐츠들에 대한 환불 처리가 완료 되었습니다.<br><br>

            무료콘텐츠인 오늘의 운세 콘텐츠는 주말 이후<br>
            순차적으로 복구할 예정 입니다.<br><br>

            마이타로 팀은 이번 장애를 계기로<br>
            안정적인 콘텐츠 경험을 제공해 드리기 위해<br>
            더욱 노력하겠습니다.<br><br>

            <strong>서비스 이용에 불편을 드려 죄송합니다.</strong></div>
        </div>
        <div class="button_area" @click="closePopup">
          {{buttonText}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted () {
  },
  data() {
    return {
      title: null,
      detail: null,
    };
  },
  props: {
    msg: String,
    buttonText: {
      type: String,
      default: '확인'
    }
  },
  methods: {
    closePopup(){
      this.$emit('close');
    },
  },
}
</script>

<style scoped>
.page{
  position: fixed;
  top: 0;
  left: 0;
  width: 100dvw;
  height: 100dvh;
  background: rgba(0, 0, 0, 0.80);
  z-index: 99999;
}

.popup{
  position: absolute;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 320px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  border-radius: 12px;
  background: #FFF;
}

.contents {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin: 37px 20px 20px 20px;
}

.text{
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 35px;
}

.title{
  margin-bottom: 20px;
  color: #000;
  text-align: center;
  font-family: Roboto, sans-serif;
  font-size: 22px;
  font-weight: 800;
}

.detail {
  color: #7F7F7F;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  text-align: center;
  line-height: 1.5;
}

.button_area {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 289px;
  height: 42px;

  border-radius: 6px;
  background: #1E2352;

  color: #FFF;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 120%; /* 19.2px */
}
</style>
