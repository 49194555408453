<template>
  <div class="desc-container">
    <div class="desc-title">
      <div class="title2">해석 목차</div>
    </div>
    <div class="desc-description">
      <div class="text4" v-html="parseText(selectedTarotBox?.tableOfContents)"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TarotDetailTableOfContents",
  components: {},
  data() {
    return {
    };
  },
  props: {
    selectedTarotBox: {
      type: Object,
    },
  },
  mounted() {},
  watch: {
  },
  computed: {
    ...mapGetters(["tarotBoxes"]),
  },
  methods: {
    parseText(text) {
      if (text) {
        // '•' 뒤에 띄어쓰기 추가
        text = text.replace(/•(?!\s)/g, "• ");
        // 줄바꿈 문자를 <br> 태그로 변환
        return text.replace(/\\n/g, "<br>");
      } else {
        return "";
      }
    }
  }
};
</script>


<style scoped>
.desc-container {
  width: 100%;
  padding: 30px 16px;
  border-bottom: 0.5px solid var(--Grayscale-Gray8);
  text-align: left;
}

.desc-title {
  margin-bottom: 10px;
}

.desc-description {
  position: relative; /* 이 부분을 추가 */
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}
</style>