import {updateVH} from "@/tarot_box/main.js";
export function logEvent(eventName, properties = null) {
    const queryParams = new URLSearchParams(window.location.search);
    const isWeb = queryParams.get('is_web') === 'true';
    const osWeb = queryParams.get('os_type') === 'web';
    const isShare = queryParams.get('is_share') === 'true';
    if (isWeb || osWeb || isShare) {
        //웹이벤트일 경우 기존 이벤트명에 'w_'를 붙여서 전송
        window.gtag('event', 'w_' + eventName, properties);
        console.log("%clogWebEvent:", "background-color: red; color: white; font-weight: bold;", 'w_'+eventName, properties);

        if(eventName === 'purchase_completed_into'){
            window.gtag('event', 'purchase', properties);
            console.log("%clogWebEvent:", "background-color: red; color: white; font-weight: bold;", 'GA4-purchase', properties);
        }
    } else {
        let dataToSend = { eventName, param: properties };
        window.callAppAction('log_event', dataToSend);
        console.log("%clogAppEvent:", "background-color: blue; color: white; font-weight: bold;", dataToSend);
    }
}

export async function showDailyFortune() {
    let dataToSend = { action: 'showDailyFortune' };
    window.sendInfoToApp(JSON.stringify(dataToSend));
    console.log("showDailyFortune", JSON.stringify(dataToSend));
}

export async function hideBottomBar() {
    let dataToSend = { action: 'hideBottomTabBar' };
    window.sendInfoToApp(JSON.stringify(dataToSend));

    let snakeCaseData = { action: 'hide_bottom_bar' };
    window.callNativeMethod(JSON.stringify(snakeCaseData));

    // console.log("hideBottomBar", JSON.stringify(dataToSend));
    for (let i = 0; i < 6; i++) {
        setTimeout(updateVH, i * 50);
    }
}

export async function showBottomBar() {
    changeBottomSafeAreaColor('#FF171B2B');
    let dataToSend = { action: 'showBottomTabBar' };
    window.sendInfoToApp(JSON.stringify(dataToSend));

    let snakeCaseData = { action: 'show_bottom_bar' };
    window.callNativeMethod(JSON.stringify(snakeCaseData));

    // console.log("showBottomBar", JSON.stringify(dataToSend));
    for (let i = 0; i < 6; i++) {
        setTimeout(updateVH, i * 50);
    }
}

export function changeTopSafeAreaColor (color) {
    let dataToSend = { action: 'changeTopSafeAreaColor', color: color };
    window.sendInfoToApp(JSON.stringify(dataToSend));
    // console.log("changeTopSafeAreaColor", JSON.stringify(dataToSend));
}

export function requestInAppReview() {
    let dataToSend = { action: 'requestInAppReview'};
    window.sendInfoToApp(JSON.stringify(dataToSend));
    // console.log("requestedInAppReview", JSON.stringify(dataToSend));
}

export function changeBottomSafeAreaColor (color) {
    let dataToSend = { action: 'changeBottomSafeAreaColor', color: color };
    window.sendInfoToApp(JSON.stringify(dataToSend));
    // console.log("changeBottomSafeAreaColor", JSON.stringify(dataToSend));
}


export function loadNaverCommonScript() {

    const checkConversionScriptExecuted = () => {
        return window._nasa && window._nasa["cnv"];
    };
    const loadScript = () => {
        const script = document.createElement("script");
        script.src = "https://wcs.naver.net/wcslog.js";
        script.onload = () => {
            if (!window.wcs_add) window.wcs_add = {};
            window.wcs_add["wa"] = "s_4ba4c64e2399";
            if (!window._nasa) window._nasa = {};
            if (window.wcs) {
                window.wcs.inflow();
                if (typeof window.wcs_do === "function") {
                    window.wcs_do(window._nasa);
                }
            }
        };
        // console.log('naverlog common script loaded');
        document.head.appendChild(script);
    };
    if (!checkConversionScriptExecuted()) {
        setTimeout(() => {
            loadScript();
        }, 3000);
    } else {
        loadScript();
    }
}

export function loadNaverConversionScript(conversionType, conversionValue) {
    if (!window._nasa) window._nasa = {};
    if (window.wcs) {
        window._nasa["cnv"] = window.wcs.cnv(conversionType, conversionValue); // 예시 값, 실제 값에 따라 변경 필요
        // console.log('conversion script loaded', conversionType, conversionValue);
    } else {
        const script = document.createElement("script");
        script.src = "https://wcs.naver.net/wcslog.js";
        script.onload = () => {
            window._nasa["cnv"] = window.wcs.cnv(conversionType, conversionValue); // 전환유형, 전환가치 설정
            // console.log('conversion script loaded', conversionType, conversionValue);
        };
        document.head.appendChild(script);
    }
}
