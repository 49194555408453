<template>
  <div class="page page-background-image">
    <TarotCardSelectPageHeader @goBack="$emit('close')" @resetCards="isResetCardConfirmPopupOpen = true;"/>

    <SelectedCardGrid :selectedCards="selectedCards" :nextCardIndex="nextCardIndex" :cardPositionData="tarot_spread.attrs"
                      style="flex:1;"/>

    <footer v-safe-area-bottom class="card-select-footer">
      <TarotCardInfoTagArea :shortDescription="tarot_spread.attrs.find(attr => attr.card_order === nextCardIndex+1)?.short_desc"/>
      <TarotCardScrollContainer :cards="selectedCards" @selectCard="selectCard"/>
    </footer>
  </div>

  <ResetCardConfirmPopup v-if="isResetCardConfirmPopupOpen"
                         @close="isResetCardConfirmPopupOpen=false" @resetCards="resetCards"/>
</template>

<script>
import TarotCardSelectPageHeader from "@/tarot_box/pages/box_input_v2/components/TarotCardSelect/TarotCardSelectPageHeader.vue";
import {mapActions, mapGetters} from "vuex";
import SelectedCardGrid from "@/tarot_box/pages/box_input_v2/components/TarotCardSelect/SelectedCardGrid.vue";
import TarotCardScrollContainer from "@/tarot_box/pages/box_input_v2/components/TarotCardSelect/TarotCardScrollContainer.vue";
import TarotCardInfoTagArea from "@/tarot_box/pages/box_input_v2/components/TarotCardSelect/TarotCardInfoTagArea.vue";
import ResetCardConfirmPopup from "@/tarot_box/pages/box_input_v2/components/TarotCardSelect/ResetCardConfirmPopup.vue";
import {logEvent} from "@/tarot_box/helper/native_api";

export default {
  name: "TarotCardSelectPage",
  emits: ['closeTarotCardSelectPage', 'confirm-cards', 'close'],
  created() {
    this.tarot_spread = this.tarotBoxes.find(box => box.id === parseInt(this.boxId)).tarotSpread;
    this.selectedCards = new Array(this.tarot_spread.attrs.length).fill(null);
  },
  mounted() {
    this.addSafeAreaColorTop('#FF211D1D');
    this.addSafeAreaColorBottom('#FF201C1C');
    logEvent('boxinput_cardselect_into', {
      "tarot_box_id": this.boxId,
      "tarot_box_name": this.tarotBoxes.find(box => box.id === parseInt(this.boxId)).title
    });
  },
  beforeUnmount() {
    this.previousSafeAreaColorTop();
    this.previousSafeAreaColorBottom();
  },
  components: {
    ResetCardConfirmPopup, TarotCardInfoTagArea, TarotCardScrollContainer, SelectedCardGrid, TarotCardSelectPageHeader},
  props: {
    boxId: {
      type: Number,
      required: true,
    },
    tarotBoxData: {
      type: Object,
      required: true,
    }
  },
  computed: {
    ...mapGetters(['user', 'tarotBoxes']),
  },
  data() {
    return {
      selectedCards: [],
      nextCardIndex: 0,
      tarot_spread: {},
      isAlreadyAskTarot: false,
      paymentDone: false,
      isResetCardConfirmPopupOpen: false,
      makeBoxResult: {},
    };
  },
  watch : {
    selectedCards: {
      handler: function (newVal) {
        if (newVal.every(item => item !== null)) {
          setTimeout(() => {
            this.askTarot();
          }, 300);
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapActions(['addSafeAreaColorTop', 'addSafeAreaColorBottom', 'previousSafeAreaColorTop', 'previousSafeAreaColorBottom']),
    goBack() {
      this.$emit('closeTarotCardSelectPage');
    },
    resetCards() {
      this.selectedCards = new Array(this.tarot_spread.attrs.length).fill(null);
      this.nextCardIndex = 0;
    },
    selectCard(selectedCardIndex) {
      if (this.nextCardIndex < this.selectedCards.length) {
        this.selectedCards[this.nextCardIndex] = selectedCardIndex;
        if(this.nextCardIndex < this.selectedCards.length - 1) {
          this.nextCardIndex++;
        }
      }
    },
    async askTarot() {
      logEvent('boxinput_cardselect_done', {
        "tarot_box_id": this.boxId,
        "tarot_box_name": this.tarotBoxes.find(box => box.id === parseInt(this.boxId)).title
      })
      //서버에서 지정된 타로카드 선택항목 불러오는 로직, 실제 유저 선택과 무관
      let picked_cards = this.tarotBoxData.tarotSpread.picked_cards.join(',');
      this.$emit('confirm-cards', picked_cards);
      console.log(picked_cards);
    }
  }
};
</script>

<style scoped>
.page {
  position:absolute;
  top:0;
  left:0;
  z-index: 200;
  display: flex;
  flex-direction: column;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  box-sizing: border-box;
  padding-top: calc(env(safe-area-inset-top) + 22px);
  padding-bottom: env(safe-area-inset-bottom);
}

.page-background-image {
  background-image: url('@/tarot_box/pages/box_input/assets/images/TarotCardSelectPage/tarot_card_select_page_bg.jpg'), linear-gradient(180deg, #211D1D 0%, #211D1D 100%);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.card-select-footer {
  display: flex;
  flex-direction: column;
  gap: 23px;
  margin-bottom: 30px;
}
</style>