<template>
  <div class="chips-tabs">
    <div v-for="(chip, index) in allCategories"
         :key="index"
         class="chips"
         :class="{ active: chip === selectedRankCategory }"
         @click="selectRankCategory(chip)">
      <span class="text2">{{ chip }}</span>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {logEvent} from "@/tarot_box/helper/native_api";

export default {
  name: "RankingChips",
  emits: ['updateCategory'],
  components: {
  },
  data() {
    return {
      selectedRankCategory: '종합'
    };
  },
  props: {
  },
  computed: {
    ...mapGetters(['user', 'tarotBoxes', 'promotionBanners', 'iconCategories', 'homeSections', 'rankingSubjectTitles']),
    allCategories() {
      return ['종합', ...this.rankingSubjectTitles];
    }
  },
  methods: {
    selectRankCategory(category) {
      this.selectedRankCategory = category;
      this.$emit('updateCategory', this.selectedRankCategory);
      logEvent('home_rank_category_click', {category_name: this.selectedRankCategory});
    }
  }
}

</script>

<style scoped>
.chips-tabs::-webkit-scrollbar {
  display: none;
}

.chips-tabs{
  display: flex;
  position: relative;
  width: 100vw;
  height: 32px;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  scroll-behavior: smooth;
  margin-right: 20px;
}

.chips{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: auto;
  padding: 0 14px;
  border-radius: 16px;
  background-color: var(--Grayscale-Gray10);
  color: var(--Grayscale-Gray4);
  margin-right: 8px;
  white-space: nowrap;
}

.chips.active {
  background-color: var(--Color-palette-Primary-Default);
}

.chips.active .text2 {
  color: var(--Grayscale-Gray12);
}
</style>