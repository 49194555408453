import * as Sentry from "@sentry/vue";

export const isProduction = process.env.NODE_ENV === 'production';

export function initSentry(app, router) {
    if (isProduction && !Sentry.getCurrentHub().getClient()) {
        Sentry.init({
            app,
            dsn: "https://358dc1f1723c4f0f86d71e32a64e9cdf@o4505140750385152.ingest.sentry.io/4505463570169856",
            integrations: [
                new Sentry.BrowserTracing({
                    routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                }),
                new Sentry.Replay(),
            ],
            beforeSend(event, hint) {
                // 무시할 오류 메시지 목록
                const ignoredErrors = [
                    "value.contains",
                    "Cannot read properties of null (reading 'contains')",
                    "Load Failed",
                    // 여기에 추가 오류 메시지를 넣을 수 있습니다
                ];

                if (hint.originalException instanceof TypeError &&
                    ignoredErrors.some(msg => hint.originalException.message.includes(msg))) {
                    return null;
                }
                return event;
            },
            // Performance Monitoring
            tracesSampleRate: 1.0,
            // Session Replay
            replaysSessionSampleRate: 0.01,
            replaysOnErrorSampleRate: 1.0,
        });

        // Vue 전역 에러 핸들러 설정
        app.config.errorHandler = (err, vm, info) => {
            // 무시할 오류 메시지 목록 (beforeSend와 동일하게 유지)
            const ignoredErrors = [
                "value.contains",
                "Cannot read properties of null (reading 'contains')",
                "Load failed",
                "Failed to fetch"
                // 여기에 추가 오류 메시지를 넣을 수 있습니다
            ];

            if (err instanceof TypeError &&
                ignoredErrors.some(msg => err.message.includes(msg))) {
                console.warn('Ignored TypeError:', err.message);
                return;
            }
            // 다른 에러는 Sentry로 전송
            Sentry.captureException(err, { extra: { componentName: vm.$options.name, info } });
            console.error('Unhandled error:', err, info);
        };
    }
}