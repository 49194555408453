<template>
 <div class="slider-control-wrapper">
   <p class="caption2" style="color: var(--Grayscale-Gray12)">
     {{ currentPanelIndex }}
   </p>
  <svg width="3" height="10" viewBox="0 0 3 10" fill="none" xmlns="http://www.w3.org/2000/svg">
     <path d="M1.5 1L1.5 9" stroke="#999999" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
   </svg>
   <p class="caption2" style="color: var(--Grayscale-Gray6)">
     {{ totalCarousel }}
   </p>
  <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
     <path d="M1 1L5 5L1 9" stroke="#999999" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
   </svg>
 </div>
</template>

<script>
export default {
  name: "SliderControls",
  components: {
  },
  data() {
    return {
    };
  },
  props: {
    currentPanelIndex:{
      type: Number,
      default: 1
    },
    totalCarousel:{
      type: Number,
      default: 1
    }
},
  mounted(){
  },
  computed: {
  },
  methods: {
  },
}

</script>

<style scoped>
.slider-control-wrapper{
  position: absolute;
  bottom: 16px;
  right: 20px;
  z-index: 3;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 63px;
  height: 22px;
  border-radius: 1000px;
  background-color: rgba(0, 0, 0, 0.75);
}
.slider-control-wrapper p{
  margin: 0 5px;
}

</style>