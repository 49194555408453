<script>
export default {
  name: "TopBar",
  props: {
    currentIndex: {
      type: Number,
      required: true
    },
    totalIndex: {
      type: Number,
      required: true
    },
  },
  emits: ['exit', 'go-back'],
  computed: {
    indicators() {
      return Array(this.totalIndex -1 ).fill(0).map((_, index) => ({
        filled: index < this.currentIndex
      }))
    }
  }
}
</script>

<template>
  <div class="top-bar-container" v-safe-area-top>
    <div class="top-bar">
      <img @click="$emit('exit')"
           src="@/tarot_box/pages/box_input_v2/assets/x-button.svg" alt="✕">

      <img :src="require('@/tarot_box/pages/box_input_v2/assets/logo.svg')">
      <div style="width: 14px;"/>
    </div>
    <div class="indicator-container" v-if="this.totalIndex !== this.currentIndex">
      <div
          v-for="(indicator, index) in indicators"
          :key="index"
          class="indicator-bar"
          :class="{ 'filled': indicator.filled }"
      ></div>
    </div>
  </div>
</template>

<style scoped>
.top-bar-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--Grayscale-Gray12);
  z-index: 51;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 44px;
  padding: 10px 24px 0 24px;
  box-sizing: border-box;
  background-color: var(--Grayscale-Gray12);
}

.indicator-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 2px;
  gap: 2px;
  padding: 10px 0 0 0;
  box-sizing: border-box;
  background-color: var(--Grayscale-Gray12);

}

.indicator-bar {
  flex-grow: 1;
  height: 2px;
  background-color: #ECECEC;
  transform: translateY(-2px);
  transition: background-color 0.3s ease;
}

.indicator-bar.filled {
  background-color: #1E2352;
}
</style>