<template>
  <IntersectionObserverComp v-on:triggerFadeIn="fadeIn">
    <transition v-on:enter="enter">
      <div v-show="isShow" ref="thumbnailWrapper">
        <div class="main-recommend">
          <TitleOnlyText :title="sectionData.title"/>
          <div class="thumbnail-area"
               @touchstart.passive="handleTouchStart"
               @touchend="handleTouchEnd">
            <ThumbnailImageM v-for="tarotBox in sectionData.tarotBoxIds" :key="tarotBox"
                             :src="isShow ? tarotBoxes.find(box => box.id === tarotBox).thumbnailImgUrl : ``"
                             @click="handleCardClick(tarotBox)"/>
          </div>
        </div>
      </div>
    </transition>
  </IntersectionObserverComp>
</template>

<script>

import {defineComponent} from "vue";
import TitleOnlyText from "@/tarot_box/pages/home/components/common/title/TitleOnlyText.vue";
import ThumbnailImageM from "@/tarot_box/pages/home/components/common/thumbnail_image/ThumbnailImageM.vue";
import {mapGetters} from "vuex";
import {logEvent} from "@/tarot_box/helper/native_api";
import IntersectionObserverComp from "@/common/IntersectionObserverComp.vue"

export default defineComponent({
  name: "ThumbnailSlideList",
  components: {IntersectionObserverComp, ThumbnailImageM, TitleOnlyText},
  inject: ["eventBus"],
  mounted() {
    this.checkIfSwipeNeeded();
  },
  computed: {
    ...mapGetters(['tarotBoxes']),
  },
  props: {
    sectionData: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      hasSwiped: false,
      needSwipe: false,
      isShow: false,
    }
  },
  methods: {
    handleCardClick(boxId) {
      this.eventBus.emit('openTarotDetail', boxId);
      const box = this.tarotBoxes.find(box => box.id === boxId);
      const boxTitle = box ? box.title : '';
      logEvent('home_recommend_thumb_click', {"tarot_box_id": boxId, "tarot_box_name": boxTitle});
      logEvent('home_contents_click', {
        "tarot_box_id": boxId,
        "tarot_box_name": boxTitle,
        "click_origin": "home_section_slide",
        "click_origin_name": this.sectionData.title});
    },
    handleTouchStart(e) {
      this.startX = e.touches[0].clientX;
    },
    handleTouchEnd(e) {
      this.endX = e.changedTouches[0].clientX;
      const diff = this.startX - this.endX;

      if (Math.abs(diff) > 50 && !this.hasSwiped && this.needSwipe) {
        logEvent('home_recommend_thumb_swipe', {});
        this.hasSwiped = true;
      }
    },
    checkIfSwipeNeeded() {
      this.$nextTick(() => {
        const container = this.$el.querySelector('.thumbnail-area');
        const thumbnails = container.querySelectorAll('ThumbnailImageM');
        let totalWidth = 0;

        thumbnails.forEach(thumb => {
          totalWidth += thumb.offsetWidth + parseInt(window.getComputedStyle(thumb).marginRight, 10);
        });
        this.needSwipe = totalWidth > window.innerWidth;
      });
    },
    enter: function (el) {
      el.style.opacity = 0;
    },
    fadeIn: function () {
      this.isShow = true;
      this.$refs.thumbnailWrapper.style = "transition: opacity 1s";
    },
  }
})
</script>

<style scoped>
.main-recommend {
  width: 100vw;
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  overflow: visible;
  padding-left: 16px;
}

.thumbnail-area {
  margin-left: -16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  overflow-x: scroll;
  :first-child {
    margin-left: 16px;
  }
  :last-child {
    padding-right: 16px;
  }
}
</style>