<template>
  <div class="category-page-wrapper"
       ref="scrollableContent"
       @touchstart="handleTouchStart"
       :class="{ 'prevent-interaction': pageAnimating }">
    <div class="detail-header-bar" :style="{ paddingTop: insetTop + 'px' }">
      <div class="back-button" @click="$emit('closeCategoryPage')">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16 20L8 12L16 4" stroke="#121212" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <h2 class="title2">{{ categoryTitle }}</h2>
      <div style="width: 24px; height: 24px; background-color: transparent"/>
    </div>
    <div class="content-container">
      <div class="total-count">
        총 {{ categoryBoxIds.length }}개
      </div>
      <div class="category-card-list">
        <CategoryCardItem
            v-for="boxId in categoryBoxIds"
            :key="boxId"
            :box-id="boxId"
            @click="handleCardClick(boxId)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CategoryCardItem from "@/tarot_box/pages/home/components/category_page/CategoryCardItem";
import { mapActions, mapGetters } from "vuex";
import { hideBottomBar, logEvent } from "@/tarot_box/helper/native_api";

export default {
  name: "CategoryPage",
  components: {CategoryCardItem},
  emits: ['closeCategoryPage'],
  inject: ["eventBus"],
  props: {
    category: {
      type: String,
      required: true,
    },
    pageAnimating: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['tarotBoxes', 'homeSections']),
  },
  data() {
    return {
      categoryTitle: '',
      categoryBoxIds: [],
      categoryType: '',
      insetTop: 16,
    };
  },
  mounted() {
    hideBottomBar();
    this.addSafeAreaColorTop('--Grayscale-Gray12');
    this.addSafeAreaColorBottom('#FFFFFFFF');
    this.setCategoryData();
    this.insetTop = parseInt(new URL(window.location.href).searchParams.get('inset_top')) + 16 || 16;
    document.body.style.overflow = 'hidden';
    document.body.style.position = 'fixed';
    document.body.style.width = '100%';
    this.$nextTick(() => {
      this.initializeScroll();
    });
  },
  beforeUnmount() {
    this.previousSafeAreaColorBottom();
    this.previousSafeAreaColorTop();
    document.body.style.overflow = '';
    document.body.style.position = '';
    document.body.style.width = '';
  },
  methods: {
    ...mapActions(['addSafeAreaColorTop', 'addSafeAreaColorBottom', 'previousSafeAreaColorTop', 'previousSafeAreaColorBottom']),
    initializeScroll() {
      const scrollableContent = this.$refs.scrollableContent;
      if (scrollableContent) {
        scrollableContent.style.webkitOverflowScrolling = 'touch';
        scrollableContent.style.overscrollBehavior = 'contain';

        // iOS Safari에서 스크롤을 즉시 활성화
        scrollableContent.style.transform = 'translateZ(0)';
        scrollableContent.style.willChange = 'transform';

        // 스크롤 위치 초기화
        setTimeout(() => {
          scrollableContent.scrollTop = 1;
          scrollableContent.scrollTop = 0;
        }, 0);
      }
    },
    handleTouchStart(event) {
      event.stopPropagation();
    },
    setCategoryData() {
      if (Number.isInteger(Number(this.category))) {
        this.setCategoryDataByType('section');
        this.categoryType = 'section';
        logEvent('home_section_list_into', {'category_name': this.categoryTitle});
      } else {
        const categoryType = this.category.split('/')[0];
        if (categoryType === 'tarot_box_subject_title') {
          this.setCategoryDataByType('subject');
          this.categoryType = 'subject';
          logEvent('home_subject_list_into', {'category_name': this.categoryTitle});
        } else if (categoryType === 'tarot_box_category') {
          this.categoryType = 'category';
          this.setCategoryDataByType('category');
          logEvent('home_category_list_into', {'category_name': this.categoryTitle});
        }
      }
    },
    setCategoryDataByType(categoryType) {
      if (categoryType === 'category') {
        this.categoryTitle = this.category.split('/')[1];
        this.categoryBoxIds = this.tarotBoxes
            .filter(box => box.category === this.categoryTitle)
            .sort((a, b) => b.categoryPriority - a.categoryPriority)
            .map(box => box.id);
      } else if (categoryType === 'subject') {
        this.categoryTitle = this.category.split('/')[1];
        this.categoryBoxIds = this.tarotBoxes
            .filter(box => box.subjectTitle.includes(this.categoryTitle))
            .sort((a, b) => b.categoryPriority - a.categoryPriority)
            .map(box => box.id);
      } else if (categoryType === 'section') {
        const categoryData = this.homeSections.find(section => section.id === Number(this.category));
        this.categoryTitle = categoryData.title;
        this.categoryBoxIds = categoryData.tarotBoxIds;
      }
    },
    handleCardClick(boxId) {
      this.eventBus.emit('openTarotDetail', boxId);
      this.logClickEvent(boxId);
    },
    logClickEvent(boxId) {
      const box = this.tarotBoxes.find(box => box.id === boxId);
      const boxTitle = box ? box.title : '';

      logEvent('home_contents_click', {
        "tarot_box_id": boxId,
        "tarot_box_name": boxTitle,
        "click_origin_name": this.categoryTitle,
        "click_origin": this.categoryType + '_list'
      });
      logEvent('home_' + this.categoryType + '_list_contents_click', {
        "tarot_box_id": boxId,
        "tarot_box_name": boxTitle
      });
    },
  }
}
</script>

<style scoped>
.prevent-interaction {
  pointer-events: none;
}

.category-page-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--Grayscale-Gray12);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9996;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: contain;
}

.detail-header-bar {
  top:0;
  position: sticky;
  width: 100%;
  padding: 16px;
  max-width: 480px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  background-color: white;
  border-bottom: 1px solid var(--Grayscale-Gray10);
  z-index: 900;
}

.back-button {
  z-index: 9999;
  cursor: pointer;
}

h2 {
  margin: 0;
}

.content-container {
  flex-grow: 1;
  padding: 20px 12px;
  box-sizing: border-box;
}

.category-card-list {
  display: flex;
  flex-wrap: wrap;
  gap: 26px 12px;
}

.total-count {
  display: flex;
  align-items: flex-start;
  margin: 12px 0;
}
</style>