<template>
  <div class="paywall">
    <Loading v-model:active="isLoading"
             loader="spinner"
             color="#1E2352"
             :opacity="0.7"
             :z-index="99999">
    </Loading>
    <TicketPurchaseConfirmPopup
            :isActive="isResultPopupActive"
            :quantity="selectedQuantity"
            :price="selectedPrice"
            @proceed="requestAppTicketPurchase(selectedId)"
            @close-popup="closePopup"/>
    <NeedMoreCloverPopup
            :isActive="isNeedMoreCloverPopupActive"
            @go-clover-paywall="$emit('go-clover-paywall')"
            @close-need-popup="closeNeedPopup"/>

    <div class="paywall-content">
      <div class="paywall-header" v-safe-area-top>
        <img style="transform: rotate(180deg)" src="@/my_page/assets/x-button.svg" @click="handleBack">
        <div class="title4">심층풀이권 구매</div>
        <div style="width: 16px; height: 16px;"/>
      </div>
      <div class="paywall-body">
        <div class="clover">
          <div class="title1">
            추가 심층풀이를 받으시겠어요?
          </div>
          <div class="text2" style="color:var(--Grayscale-Gray5)">
            해석을 보시다가 더 상세히 풀이받고 싶은 부분이 있으시다면 질문하여 고민을 풀어보세요
          </div>
        </div>
        <div class="clover-price">
          <div class="clover-price-header">
            <div class="title5" style="margin-right: 5px; color:var(--Grayscale-Gray5)">내 보유 클로버</div>
            <div class="my-clover">
              <div class="title4">{{formatNumber(userClover)}}</div>
              <img :src="require('@/tarot_box/pages/today_fortune/assets/clover-s.svg')">
            </div>
          </div>
          <div v-for="item in sortedPaywallItems" :key="item.quantity" class="clover-tier" @click="openResultPopup(item.quantity, item.clover_price, item.product_id)">
            <div v-if="item.quantity === 10" class="tag title5">최대할인</div>

            <div class="quantity">
              <div class="text2" style="color:var(--Grayscale-Gray5)">심층풀이권</div>
              <div class="title3">{{item.quantity}}개</div>
            </div>

            <div class="price">
                <span class="text2 gray7" v-if="item.quantity*13 !== item.clover_price" style="display: flex; flex-direction: row; gap: 2px; align-items: flex-end; margin-right: 4px; text-decoration-line: line-through">
                  {{item.quantity * 13}}
                  <img style="transform: scale(90%); filter:opacity(0.6)" :src="require('@/tarot_box/pages/today_fortune/assets/clover-s.svg')">
                </span>
              <div class="title2">
                {{item.clover_price}}
                <img style="transform: scale(130%)" :src="require('@/tarot_box/pages/today_fortune/assets/clover-s.svg')">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="disclaimer">
        <div class="title3 gray6">심층풀이 이용안내</div>
        <ul>
          <li>심층풀이 다회권 구매 후 7일 이내, 사용하지 않은 경우에만 결제 취소가 가능합니다. 결제한 심층풀이 다회권의 일부라도 사용하시는 경우 전체에 대하여 결제 취소가 불가능합니다.</li>
          <li>법정대리인의 동이 없는 미성년자의 결제는 취소될 수 있습니다.</li>
          <li>위 표기된 결제 금액은 부가가치세(10%)가 포함된 금액입니다.</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import TicketPurchaseConfirmPopup from "@/paywall/component/TicketPurchaseConfirmPopup.vue";
import NeedMoreCloverPopup from "@/paywall/component/NeedMoreCloverPopup.vue";
import {
  getTarotUserInfo,
  purchaseExtraQuestionTicket,
} from "@/tarot_box/helper/mytarot_api";
import {mapGetters} from "vuex";
import Loading from "vue-loading-overlay";
import {hideBottomBar, logEvent, showBottomBar} from "@/tarot_box/helper/native_api";

export default {
  name: "ExtraQuestionTicketPaywall.vue",
  inject:['appListener', 'appCommander'],
  components: {
    Loading,
    TicketPurchaseConfirmPopup,
    NeedMoreCloverPopup
  },
  data() {
    return {
      userClover: 0,
      userId: 0,
      userPhoneNumber: '',
      userTicket: 0,
      osType: '',
      selectedQuantity: 0,
      selectedPrice: 0,
      selectedId: 0,
      isResultPopupActive: false,
      isNeedMoreCloverPopupActive: false,
      purchaseSubscription: null,
      isSuccess: false,
      isLoading: false,
    };
  },
  props: {
    isTeleported: {
      type: Boolean,
      default: false
    },
    restoreBottomBar: {
      type: Boolean,
      default: false,
    },
    ticketData: {
      type: Array,
      default: () => []
    },
    tarotUserData: {
      type: Object,
      default: () => {}
    }
  },
  beforeMount() {
  },
  mounted() {
    hideBottomBar();
    logEvent ('paywall_into', {});
    const urlParams = new URLSearchParams(decodeURIComponent(window.location.search));
    this.userId = parseInt(urlParams.get('tarot_user_id'));
    this.userPhoneNumber = urlParams.get('phone_number');
    this.osType = urlParams.get('os_type');
    this.getUserData();
    window.onBackButtonClick = this.handleBack;
    logEvent('e_question_paywall_into', {})
  },
  unmounted() {
    this.purchaseSubscription?.unsubscribe();
  },
  computed: {
    ...mapGetters(['paywall']),
    sortedPaywallItems() {
      return [...this.ticketData].sort((a, b) => b.quantity - a.quantity);
    },
  },
  methods: {
    formatNumber(num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    async getUserData() {
      let res = await getTarotUserInfo(this.userId, this.userPhoneNumber);
      if (res.result === 'success') {
        this.userClover = res.data.clover_balance;
      }
    },
    openResultPopup(quantity, price, id) {
      if (this.userClover < price) {
        this.isNeedMoreCloverPopupActive = true;
        logEvent('e_question_paywall_not_enough', {
          'product_id': id,
          'clover_price': price,
          'quantity': quantity
        })
        return;
      }
      this.selectedQuantity = quantity;
      this.selectedPrice = price;
      this.selectedId = id
      this.isResultPopupActive = true;
    },

    async requestAppTicketPurchase(productId) {
      logEvent('e_question_paywall_click', {
        'product_id': productId,
        'clover_price': this.selectedPrice,
        'quantity': this.selectedQuantity
      })
      this.isLoading = true;
      this.isResultPopupActive = false;
      const urlParams = new URLSearchParams(decodeURIComponent(window.location.search));
      const tarotUserId = parseInt(urlParams.get('tarot_user_id'));
      const phoneNumber = urlParams.get('phone_number');
      let res = await purchaseExtraQuestionTicket(tarotUserId, phoneNumber, productId);
      if (res.result === 'success') {
        await this.getUserData();
        logEvent('e_question_paywall_success', {
          'product_id': productId,
          'clover_price': this.selectedPrice,
          'quantity': this.selectedQuantity
        })
        this.$emit('alert-toast', `심층풀이 ${this.selectedQuantity}회권 구매가 완료되었습니다.`);
        this.$emit('close-ticket')
      } else {
        this.$emit('alert-toast', '심층풀이권 구매에 실패했습니다. 다시 시도해주세요.');
      }
      this.isLoading = false;
    },
    handleBack() {
      if (this.isTeleported) {
        this.$emit('close-ticket');
        if (this.restoreBottomBar) {
          showBottomBar();
        }
      } else {
        showBottomBar();
        this.$router.go(-1);
      }
    },
    async closePopup() {
      await this.getUserData();
      this.isLoading = false;
      this.isResultPopupActive = false;
      if (this.restoreBottomBar) {
        showBottomBar();
      }
    },
    async closeNeedPopup(){
      console.log('fuck')
      await this.getUserData();
      this.isNeedMoreCloverPopupActive = false;
    }
  }
}
</script>

<style scoped>
.title3, .title2, .title5, .title4, .text2, .title1, .price {
  font-family: "SUIT Variable", sans-serif;
}

.paywall {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9998;
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: white;
}

.paywall-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
}

.paywall-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 24px;
  background-color: white;
}

.paywall-body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.clover {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  gap: 12px;
  padding: 24px 16px;
  box-sizing: border-box;
}

.clover-price {
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  box-sizing: border-box;
  width: 100%;
}

.clover-price span{
  font-family: "SUIT Variable", sans-serif;
}

.title4{
  font-family: "SUIT Variable", sans-serif;
}

.clover-price-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 20px;
  background: var(--Grayscale-Gray11);
  border-radius: 20px;
  margin: 6px 0 24px 0;
}

.my-clover {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2px;
}

.quantity{
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.clover-tier {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  gap: 4px;
  padding: 16px 24px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid var(--Grayscale-Gray7);
}

.clover-tier:nth-child(2){
  border: 2px solid #2E89F2;
}

.tag{
  position: absolute;
  top: -14px;
  right: 20px;
  color: #FFF;
  padding: 6px 8px;
  border-radius: 8px;
  background-image: linear-gradient(90deg, #2E89F2 0%, #363DD6 100%);

}

.clover-tier > .price {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.disclaimer {
  margin-top: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 24px;
  box-sizing: border-box;
  font-size: 12px;
  color: var(--Grayscale-Gray6);
  background: var(--Grayscale-Gray10);
}

.disclaimer ul {
  padding: 30px 0 0 24px;
  text-align: left;
}

.disclaimer li {
  margin-bottom: 8px;
  line-height: 150%;
}
</style>
