<template>
  <div class="component-container" :style="{ paddingTop: padding}">
    <div class="heading-texts" v-safe-area-top>
      <img id="symbol" :src="tarotBoxHistory.output_page_meta_data.objet_icon_image_url" alt="symbol"/>
      <span class="font14 gray5">
        <strong>Chapter {{ currentChapterIndex }}</strong>
      </span>
      <span class="serif gray1 font20">{{ currentPageData.page_title }}</span>
    </div>

    <div class="tarot-spread-container" ref="spreadContainer" :style="{ opacity: imagesLoaded ? 1 : 0, transition: imagesLoaded ? 'opacity 0.5s' : 'none' }">
      <div class="tarot-spread" :style="spreadStyle">
        <div
            v-for="(attr, index) in currentPageData.page_content.spread_data.attrs"
            :key="index"
            class="tarot-card"
            :style="{
    left: `${attr.orientation === 'horizontal' ? (attr.axis_x - attr.card_display_size * 1.4 / 2) * spreadRatio : (attr.axis_x - attr.card_display_size / 2) * spreadRatio}px`,
    top: `${attr.orientation === 'horizontal' ? (attr.axis_y - attr.card_display_size * 1.4 / 2) * spreadRatio : (attr.axis_y * 1.1 - attr.card_display_size * 1.4 / 2) * spreadRatio}px`,
    width: `${attr.orientation === 'horizontal' ? attr.card_display_size * spreadRatio : attr.card_display_size * spreadRatio}px`,
    height: `${attr.orientation === 'horizontal' ? attr.card_display_size * 1.4 * spreadRatio : attr.card_display_size * 1.4 * spreadRatio}px`,
    transform: attr.orientation === 'horizontal' ? 'rotate(90deg) translate(0, -50%)' : 'none',
    'transform-origin': 'center center'
  }"
        >
          <div class="card-order gray2"><strong>{{ getCardOrder(index) }}</strong></div>
          <img
              @click="openCardInfo([currentPageData.page_content.card_data[index], currentPageData.page_content.spread_data.attrs[index]])"
              :src="getCardImageUrl(currentPageData.page_content.card_data[index].card_id)"
              :alt="currentPageData.page_content.card_data[index].card_name"
              :class="{ 'no-transition': cachedImages.includes(getCardImageUrl(currentPageData.page_content.card_data[index].card_id)) }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {logEvent} from "@/tarot_box/helper/native_api";

export default {
  name: "TarotSpread",
  props: {
    currentIndex: {
      type: Number,
    },
    currentPageData: {
      type: Object,
    },
    backgroundColor: {
      type: String,
    },
    tarotBoxHistory: {
      type: Object,
    },
    currentChapterIndex: {
      type: Number,
    },
    isShare: {
      type: Boolean,
    },
  },
  emits: ['open-card-info'],
  data() {
    return {
      spreadRatio: 1,
      imagesLoaded: false,
      cachedImages: [],
    };
  },
  computed: {
    spreadStyle() {
      const maxX = Math.max(...this.currentPageData.page_content.spread_data.attrs.map(attr =>
          attr.orientation === 'horizontal' ? attr.axis_x + attr.card_display_size * 1.4 : attr.axis_x + attr.card_display_size
      ));
      const maxY = Math.max(...this.currentPageData.page_content.spread_data.attrs.map(attr => attr.axis_y + attr.card_display_size * 1.4));

      const offsetX = this.currentPageData.page_content.spread_data.attrs.reduce((sum, attr) =>
          attr.orientation === 'horizontal' ? sum + attr.card_display_size * 0.3 : sum, 0);

      return {
        width: `${(maxX - offsetX) * this.spreadRatio}px`,
        height: `${maxY * this.spreadRatio}px`,
        marginLeft: `${offsetX * this.spreadRatio}px`,
      };
    },
    padding() {
      return this.isShare ? '48px' : '100px';
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.resizeSpread();
      logEvent('result_tarot_spread_into', {})
    });
    window.addEventListener('resize', this.resizeSpread);

    const images = this.$el.querySelectorAll('img');
    const totalImages = images.length;
    let loadedImages = 0;

    const onImageLoad = (img) => {
      loadedImages++;
      if (img.complete) {
        this.cachedImages.push(img.src);
      }
      if (loadedImages === totalImages) {
        setTimeout(() => {
          this.imagesLoaded = true;
        }, 50); // 200ms의 지연 시간 추가
      }
    };

    images.forEach(img => {
      if (img.complete) {
        onImageLoad(img);
      } else {
        img.addEventListener('load', () => onImageLoad(img));
      }
    });
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.resizeSpread);
  },
  methods: {
    getCardImageUrl(cardId) {
      return `https://firebasestorage.googleapis.com/v0/b/tarot-gpt.appspot.com/o/tarot_card_imgs%2Flite_0925%2F${cardId}.png?alt=media`;
    },
    resizeSpread() {
      const containerWidth = this.$refs.spreadContainer.clientWidth * 0.75;
      const containerHeight = this.$refs.spreadContainer.clientHeight * 0.75;
      const spreadWidth = Math.max(...this.currentPageData.page_content.spread_data.attrs.map(attr => attr.axis_x + attr.card_display_size));
      const spreadHeight = Math.max(...this.currentPageData.page_content.spread_data.attrs.map(attr => attr.axis_y + attr.card_display_size * 1.4));

      const widthRatio = containerWidth / spreadWidth;
      const heightRatio = containerHeight / spreadHeight;

      this.spreadRatio = Math.min(widthRatio, heightRatio);
    },
    getCardOrder(index) {
      const orders = ['첫번째', '두번째', '세번째', '네번째', '다섯번째', '여섯번째', '일곱번째', '여덟번째', '아홉번째', '열번째'];
      return orders[index];
    },
    openCardInfo(cardId) {
      logEvent('result_spread_card_click', { 'card_id': cardId })
      this.$emit('open-card-info', cardId);
    },
  },
};
</script>

<style scoped>
.component-container {
  width: 100vw;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
}

.heading-texts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 6px;
}

#symbol {
  height: 30px;
}

.tarot-spread-container {
  margin-top: 36px;
  position: relative;
  width: 100vw;
  height: 80vh;
}

.tarot-spread {
  position: relative;
  display: inline-block;
}

.tarot-card {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 12px;
}

.tarot-card img {
  width: 100%;
  height: auto;
  -webkit-filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.2));
  filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.2));
  transition: opacity 0.5s;
}

.tarot-card img.no-transition {
  transition: none;
}

.card-order {
  margin-bottom: 2px;
  font-size: 10px;
}

.card-description {
  margin-top: 8px;
  font-size: 14px;
}
</style>
