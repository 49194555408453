<template>
  <div class="confirm-popup-back-page">
    <div class="confirm-popup">
      <div class="confirm-popup-contents">
        <div class="confirm-popup-contents-title">
          카드를 다시 뽑을까요?
        </div>
        <div class="confirm-popup-contents-delete">
          뽑았던 카드가 지워지고<br>
          첫카드부터 다시 뽑을 수 있어요
        </div>
      </div>

      <div class="confirm-popup-button-area">
        <div class="confirm-popup-button" v-wave @click="handleButtonFunction()">
          취소
        </div>
        <div class="confirm-popup-button" v-wave @click="handleButtonFunction('resetCards')">
          카드 다시 뽑기
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: 'ResetCardConfirmPopup',
  emits: ['close', 'resetCards'],
  mounted() {
    this.addSafeAreaColorTop('#FF646464');
    this.addSafeAreaColorBottom('#FFFDFDFD');
  },
  beforeUnmount() {
    this.previousSafeAreaColorTop();
    this.previousSafeAreaColorBottom();
  },
  methods: {
    ...mapActions(['addSafeAreaColorTop', 'addSafeAreaColorBottom', 'previousSafeAreaColorTop', 'previousSafeAreaColorBottom']),
    async handleButtonFunction(buttonType = null) {
      if(buttonType) {
        this.$emit(buttonType);
      }
      this.$emit('close');
    }
  },
}
</script>

<style scoped>
.confirm-popup-back-page{
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  background: rgba(0, 0, 0, 0.80);
}

.confirm-popup{
  position: absolute;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 30px;
  width: 280px;
  height: fit-content;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 15px;
  box-sizing: border-box;

  border-radius: 12px;
  background: #FFF;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.15);
}

.confirm-popup-contents{
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  gap: 8px;
}

.confirm-popup-contents-title{
  color: #000;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
}

.confirm-popup-contents-delete {
  text-align: center;
  color: #000;
  font-size: 14px;
  font-weight: 400;
}

.confirm-popup-button-area {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  gap: 9px;
}

.confirm-popup-button {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0;

  background: #1E2352;
  color: #FFF;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 700;
}

.confirm-popup-button:first-child {
  background: #E9E9E9;
  color: #6E6E6E;
}

.confirm-popup-button:last-child {
  background: #1E2352;
  color: #FFF;
}
</style>