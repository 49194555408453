<template>
  <div class="gender-input">
    <div class="button-container">
      <button
          v-for="option in options"
          :key="option.value"
          :class="['gender-button', { 'selected': modelValue === option.value }]"
          @click="selectGender(option.value)"
      >
        {{ option.label }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "GenderInput",
  props: {
    inputItem: {
      type: Object,
      required: true
    },
    modelValue: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      options: [
        { value: '남성', label: '남성' },
        { value: '여성', label: '여성' }
      ]
    }
  },
  methods: {
    selectGender(value) {
      this.$emit('update:modelValue', value);
      this.$emit('submitAnswer', {
        ...this.inputItem,
        value: value,
        inputFormType: this.inputItem.inputFormType,
        inputFormSubtype: this.inputItem.inputFormSubtype
      });
    }
  },
  emits: ['update:modelValue', 'submitAnswer', 'focus']
}
</script>

<style scoped>
.gender-input {
  width: 100%;
}

.button-container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.gender-button {
  flex: 1;
  padding: 16px 16px;
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 700;
  background: var(--Grayscale-Gray10, #ECECEC);
  color: var(--Grayscale-Gray3, #333);
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.gender-button.selected {
  background: var(--Grayscale-Gray2, #242424);
  color: white;
}
</style>
